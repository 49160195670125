import axios from "axios";

class JobProFormasAPI {

  jobId: string | undefined;

  setJobId(jobId: int | string) {
    this.jobId = jobId
  }

  /**
   * Show a list of the equipment items that are attached to this job that are attached do this
   * @param filters
   */
  index(filters: any = null) {
    return axios.get(`/jobs/${this.jobId}/pro-formas`)
  }

  /**
   * Move an equipment item to a location
   * @param data
   */
  store(data: object) {
    return axios.post(`/jobs/${this.jobId}/pro-formas`, data)
  }
  update(id: number | string, data: object) {
    return axios.put(`/jobs/${this.jobId}/pro-formas/${id}`, data)
  }

  delete(id: number) {
    return axios.delete(`/jobs/${this.jobId}/pro-formas/${id}`)
  }
}
export const jobProFormasAPI = new JobProFormasAPI()
