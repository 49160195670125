<script setup lang="ts">
import {defineEmits, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {jobProFormasAPI as API} from "@/api/jobs/JobProFormas";
import Say from "@/utils/Say";
import SayErrorResponse from "@/mixins/sayErrorResponse";

const {t} = useI18n()


const emit = defineEmits(['approved', 'cancelForm'])
const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  checkoutPeriod: {
    type: Object,
    required: false
  }
})

const jobId = inject('jobId', 0)
API.setJobId(jobId.value)
const dialogTitle = inject('dialogTitle', null)
const formatDate = inject('formatDate')

onMounted(() => {
  dialogTitle.value = t('createProforma')
})

const formSubmitted = ref(false)
const submit = () => {
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true
  API.store({
    start_date: props.checkoutPeriod.start_date,
    end_date: props.checkoutPeriod.end_date,
    checkout_period_id: props.checkoutPeriod.id
  }).then(
    (data) => {
      if (data.error) {
        SayErrorResponse(data.error);
      } else {
        Say('success', t('proFormaCreated'));
        emit('created')
      }
    },
    (error) => {
      SayErrorResponse(error)
    },
  ).finally(() => {
    formSubmitted.value = false
  })
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})
</script>

<template>
  <div>
      <template v-if="$i18n.locale === 'nl'">
        <p class="mb-2">
          Dit is alleen mogelijk als alle werkorders van onderstaande periode zijn goedgekeurd.
        </p>
        Zodra deze pro forma is aangemaakt:
        <ul class="ml-6 mb-4">
          <li>Kunnen de werkorders die in deze periode vallen niet meer worden aangepast.</li>
          <li>Kan de huur start van alle materialen van dit project alleen worden ingesteld op een datum die na
            onderstaande einddatum komt.
          </li>
          <li>Kan de huur einde van alle materialen van dit project alleen worden ingesteld op een datum die gelijk is
            aan, of komt na onderstaande einddatum.
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="mb-2">
          This is only possible if all work orders for the period below have been approved.
        </p>
        Once this pro forma has been created:
        <ul class="ml-6 mb-4">
          <li>The work orders that fall within this period can no longer be adjusted.</li>
          <li>The rental start of all equipment for this project can only be set on a date after the end date below.
          </li>
          <li>The rental end of all equipment for this project can only be set on a date equal to or after the end date
            below.
          </li>
        </ul>
      </template>
      <v-row>
        <v-col sm="6">
          <div class="datepickerWrapper">
            <div class="datepickerLabel">{{ t('proFormaFromDate') }}</div>
            <div class="font-weight-bold">{{ formatDate(checkoutPeriod.start_date) }}</div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <div class="datepickerWrapper">
            <div class="datepickerLabel">{{ t('proFormaToDate') }}</div>
            <div class="font-weight-bold">{{ formatDate(checkoutPeriod.end_date) }}</div>
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<style scoped lang="scss">

</style>
