import axios from 'axios'

export function getProForma (proFormaId: number|string) {
  return axios.get('/pro-formas/' + proFormaId,{
    responseType: 'blob' // Important to handle binary PDF data
  })
}

export function rejectProForma (proFormaId: number|string) {
  return axios.post('/pro-formas/' + proFormaId + '/reject')
}

export function approveProForma (proFormaId: number|string) {
  return axios.post('/pro-formas/' + proFormaId + '/approve')
}