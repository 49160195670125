<script setup lang="ts">
import {computed, onBeforeMount, provide, ref, watch, inject} from "vue";
import SayErrorResponse from '@/mixins/sayErrorResponse';
import {useI18n} from "vue-i18n";
import { fetchCheckoutPeriods, fetchCheckoutPeriodsCostOverview } from '@/api/checkoutPeriods';

import CreateProforma from "@/components/Jobs/View/Invoices/CreateProforma";
import ApproveProforma from "@/components/Jobs/View/Invoices/ApproveProforma";
import RejectProforma from "@/components/Jobs/View/Invoices/RejectProforma";
import OverviewRowActions from "@/components/Jobs/View/Invoices/OverviewRowActions";
import CheckoutPeriodRow from "./Invoices/CheckoutPeriodRow.vue";

const {t} = useI18n()
const jobId = inject('jobId', 0)
const checkoutPeriods = ref([])
const checkoutPeriodsCostOverview = ref([])
const conceptCheckoutPeriod = ref(null)
const futureConceptCheckoutPeriod = ref(null)

onBeforeMount(async () => {
  loadCheckoutPeriods()
  loadCheckoutPeriodsCostOverview()
});

const loadCheckoutPeriods = async () => {
  try {
    const response = await fetchCheckoutPeriods(jobId.value);
    if(!response.data) {
      SayErrorResponse(response.data.error);
    } else {
      checkoutPeriods.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const loadCheckoutPeriodsCostOverview = async () => {
  try {
    const response = await fetchCheckoutPeriodsCostOverview(jobId.value);
    if(!response.data.generated_at) {
      SayErrorResponse(response.data.error);
    } else {
      checkoutPeriodsCostOverview.value = response.data;
    }
  } catch (error) {
    SayErrorResponse(error);
  }
}

const formValid = ref(false)
const formData = ref()

const dialogOpen = ref(false)
const dialogForm = ref(null)
const dialogFormModel = ref(null)
const checkoutPeriodSelected = ref(null)
const dialogTitle = ref(null)
provide('dialogTitle', dialogTitle)

/**
 * Todo: Change the way that the dialog closes,
 * Currently the form closes and then cause the dialog to close, this is not nice from a UI view
 * Change so that a dialog close is first triggered and then that resets the form
 */

watch(() => dialogForm.value, (newValue) => {
  dialogOpen.value = !!newValue
})

const formComponent = computed(() => {
  if (dialogForm.value == 'CreateProforma') {
    return CreateProforma;
  }
  if (dialogForm.value == 'ApproveProforma') {
    return ApproveProforma;
  }
  if (dialogForm.value == 'RejectProforma') {
    return RejectProforma;
  }
  return null
})

const triggerRowAction = ({action, invoice, checkoutPeriod}) => {
  checkoutPeriodSelected.value = null

  if (action === 'openApproveDialog') {
    dialogFormModel.value = invoice
    dialogForm.value = 'ApproveProforma'
  } else if (action === 'openRejectDialog') {
    dialogFormModel.value = invoice
    dialogForm.value = 'RejectProforma'
  } else if (action === 'openCreateDialog') {
    dialogFormModel.value = {
      dateFrom: checkoutPeriod.start_date,
      dateTo: checkoutPeriod.end_date
    }
    checkoutPeriodSelected.value = checkoutPeriod
    dialogForm.value = 'CreateProforma'
  } else {
    throw Error(`Unknown Action: ${action}`)
  }
}

const dialogClosed = () => {
  dialogForm.value = null
  dialogFormModel.value = null
  loadCheckoutPeriods()
  loadCheckoutPeriodsCostOverview()
}

const formatDate = (date: string) => {
  if (!date) {return ''}
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}
provide('formatDate', formatDate)
const hasConceptCheckoutPeriod = (checkoutPeriodsCostOverview: { checkout_periods: string | any[]; }) => {
  for (let i = 0; i < checkoutPeriodsCostOverview.checkout_periods.length; i++) {
    if (checkoutPeriodsCostOverview.checkout_periods[i].id === null && checkoutPeriodsCostOverview.checkout_periods[i].is_future !== true) {
      conceptCheckoutPeriod.value = checkoutPeriodsCostOverview.checkout_periods[i]
      return true
    }
  }
  return false
}
const hasFutureConceptCheckoutPeriod = (checkoutPeriodsCostOverview: { checkout_periods: string | any[]; }) => {
  for (let i = 0; i < checkoutPeriodsCostOverview.checkout_periods.length; i++) {
    if (checkoutPeriodsCostOverview.checkout_periods[i].id === null && checkoutPeriodsCostOverview.checkout_periods[i].is_future === true) {
      futureConceptCheckoutPeriod.value = checkoutPeriodsCostOverview.checkout_periods[i]
      return true
    }
  }
  return false
}
</script>

<template>
  <v-row v-if="false">
    <v-col>
      <v-btn
        :text="t('add')"
        @click="dialogForm = 'CreateProforma'"
      />
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <table class="checkout-periods-table">
        <thead>
          <tr>
            <th>{{ t('checkoutPeriod') }}</th>
            <th>{{ t('type') }}</th>
            <th>{{ t('number') }}</th>
            <th></th>
            <th>{{ t('date') }}</th>
            <th>{{ t('status') }}</th>
            <th>{{ t('invoiceTotalExcl') }}</th>
            <th>{{ t('actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="checkoutPeriods.length" v-for="checkoutPeriod in checkoutPeriods" :key="checkoutPeriod.id">
            <!-- First row with checkout period date and first pro forma -->
            <tr>
              <td :rowspan="checkoutPeriod.pro_formas.length > 0 ? checkoutPeriod.pro_formas.length : 1" class="bg-white">
                {{ formatDate(checkoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(checkoutPeriod.end_date) }}
              </td>
              <template v-if="checkoutPeriod.pro_formas.length">
                <CheckoutPeriodRow :proForma="checkoutPeriod.pro_formas[0]" />
                <td width="140px">
                  <OverviewRowActions :invoice="checkoutPeriod.pro_formas[0]" @trigger="triggerRowAction" />
                </td>
              </template>
              <template v-else>
                <td colspan="6"></td>
                <td>
                  <OverviewRowActions :invoice="{}" :checkout-period="checkoutPeriod" @trigger="triggerRowAction" />
                </td>
              </template>
            </tr>
            <!-- Additional rows for the remaining pro formas -->
            <tr v-for="proForma in checkoutPeriod.pro_formas.slice(1)" :key="proForma.id">
                <CheckoutPeriodRow :proForma="proForma" />
                <td width="140px">
                  <OverviewRowActions :invoice="proForma" @trigger="triggerRowAction" />
                </td>
            </tr>
          </template>
          <template v-else-if="checkoutPeriodsCostOverview.generated_at && !checkoutPeriodsCostOverview.length && !hasConceptCheckoutPeriod(checkoutPeriodsCostOverview) && !hasFutureConceptCheckoutPeriod(checkoutPeriodsCostOverview)">
            <tr>
              <td colspan="8" class="bg-white"><i>{{ t('noCheckoutPeriodsFound') }}</i></td>
            </tr>
          </template>
          <tr v-if="!checkoutPeriodsCostOverview.generated_at">
            <td colspan="8" class="bg-white">
              <p>
                <v-progress-circular indeterminate color="primary" :size="16" width="3" class="mr-2"></v-progress-circular>
                <i>{{ t('checkingForConceptCheckoutPeriods') }}</i>
              </p>
            </td>
          </tr>
          <tr v-if="checkoutPeriodsCostOverview.generated_at">
            <template v-if="hasConceptCheckoutPeriod(checkoutPeriodsCostOverview)">
              <td class="bg-white">{{ formatDate(conceptCheckoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(conceptCheckoutPeriod.end_date) }} &nbsp; ({{ t('concept') }})</td>
              <td colspan="7" class="bg-white"><i>{{ t('conceptCheckoutPeriodInsctructions') }}</i></td>
            </template>
          </tr>
          <tr v-if="checkoutPeriodsCostOverview.generated_at">
            <template v-if="hasFutureConceptCheckoutPeriod(checkoutPeriodsCostOverview)">
              <td class="bg-white">{{ formatDate(futureConceptCheckoutPeriod.start_date) }} {{ t('upToAndIncludingAbbreviation') }} {{ formatDate(futureConceptCheckoutPeriod.end_date) }} &nbsp; ({{ t('concept') }})</td>
              <td colspan="7" class="bg-white"><i>{{ t('futureConceptCheckoutPeriodInsctructions') }}</i></td>
            </template>
          </tr>
        </tbody>
      </table>

    </v-col>
  </v-row>


  <v-dialog
    v-model="dialogOpen"
    width="400"
    id="invoicing-action-dialog"
    @update:model-value="dialogClosed()"
  >
    <v-card
      max-width="400"
      prepend-icon="mdi-pencil"
      :title="dialogTitle"
    >
      <template v-slot:text>
        <formComponent
          v-if="formComponent"
          ref="formData"
          :invoice="dialogFormModel"
          :checkout-period="checkoutPeriodSelected"
          @created="dialogClosed()"
          @approved="dialogClosed()"
          @rejected="dialogClosed()"
          @cancel-form="dialogClosed()"
        />
      </template>
      <template v-slot:actions>
        <v-btn
          :text="t('save')"
          @click="$refs.formData.submit()"
          type="submit"
        />
        <v-btn
          :text="t('cancel')"
          @click="$refs.formData.cancel()"
        />
      </template>
    </v-card>
  </v-dialog>
</template>
<style ang="scss">
th, td {
  white-space: nowrap;
}
.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-card,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-sheet,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > form > .v-card,
.v-dialog#invoicing-action-dialog  > .v-overlay__content > form > .v-sheet {
  overflow: initial;
}

.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-card .v-card-item {
  background: var(--custom-bg) !important;
  margin-bottom: 15px;
}
.v-dialog#invoicing-action-dialog  > .v-overlay__content > .v-card .v-card-text {
  ;
}

.no-wrap {
  white-space: nowrap;
}

.checkout-periods-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  font-size: 10.5px;
}

.checkout-periods-table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-property: 'box-shadow', 'opacity', 'background', 'height';
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.checkout-periods-table tr:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.checkout-periods-table th, .checkout-periods-table td {
  vertical-align: top;
}

.checkout-periods-table th {
  padding: 12px 16px;
  text-align: left;
  background-color: #fff;
  font-weight: 500;
}

.checkout-periods-table td {
  padding: 8px 16px;
}

.bg-white {
  background-color: #fff;
}
</style>
