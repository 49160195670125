<script setup lang="ts">
import { rejectProForma } from '@/api/proFormas';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say'
import {defineEmits, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n()

const emit = defineEmits(['rejected', 'cancelForm'])
const props = defineProps({
  invoice: {
    type: Object,
    required: true
  }
})

const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
const formRules = ref({
  confirmationCheck: [
    value => {
      if (value) return true
      return t('pleaseConfirm')
    },
  ],
})

const confirmationCheck = ref(false)


const dialogTitle = inject('dialogTitle', null)
onMounted(() => {
  dialogTitle.value = t('rejectProforma')
})

const formSubmitted = ref(false)
const submit = () => {
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true

  if (form.value.validate() && !formValid.value) {
    return
  }

  rejectProForma(props.invoice.id)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response.data.error);
      } else {
        Say('success', t('proFormaRejectedSuccessfully'));
        emit('rejected')
      }
    })
    .catch(error => {
      SayErrorResponse(error);
    })
    .finally(() => {
      formSubmitted.value = false
    });
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})

</script>

<template>
  <div>
    <v-form
      v-model="formValid"
      ref="form"
    >
      <template v-if="$i18n.locale === 'nl'">
        Hiermee wordt de pro forma  afgekeurd. Er zal direct een kopie van de pro forma  worden aangemaakt die de status 'Concept' heeft.
      </template>
      <template v-else>
        This rejects the pro forma. A copy of the pro forma will be immediately created with the status 'Draft'.
      </template>

      <p class="mt-4">{{$t('toConfirm')}}:</p>

      <v-checkbox
        v-model="confirmationCheck"
        :label="t('proFormaRejectionLabel')"
        :rules="formRules.confirmationCheck"
        hide-details
      />
    </v-form>
  </div>
</template>

<style scoped lang="scss">

</style>
