<template>
  <div>
    <v-tabs v-model="tab" density="compact">
      <v-tab v-for="tab in tabs" :value="tab">{{ $t(`JobTab${tab}`) }}</v-tab>
    </v-tabs>
    <div class="pt-3">
      <Overview v-if="tab === 'Overview'"></Overview>
      <Workorders v-if="tab === 'Workorders'"></Workorders>
      <Equipment v-if="tab === 'Equipment'"></Equipment>
      <Map v-if="tab === 'Map'"></Map>
      <Documents v-if="tab === 'Documents'"></Documents>
      <Notes v-if="tab === 'Notes'"></Notes>
      <template v-if="showJobFinancials">
        <AdditionalCosts v-if="tab === 'AdditionalCosts'"></AdditionalCosts>
        <CostOverview v-if="tab === 'CostOverview'"></CostOverview>
        <Invoices v-if="tab === 'Invoices'"></Invoices>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Overview from '@/components/Jobs/View/Overview';
import Workorders from '@/components/Jobs/View/Workorders';
import Equipment from '@/components/Jobs/View/Equipment';
import Map from '@/components/Jobs/View/Map';
import Invoices from '@/components/Jobs/View/Invoices';
import CostOverview from '@/components/Jobs/View/CostOverview';
import Documents from '@/components/Jobs/View/Documents';
import Notes from '@/components/Jobs/View/Notes';
import AdditionalCosts from "@/components/Jobs/View/AdditionalCosts.vue";
import useProjectTabSelector from '@/composable/useProjectTabSelector';

import {computed, provide, ref, watch, onBeforeMount} from 'vue'
import {useRoute} from 'vue-router';
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

const { projectTabSelector } = useProjectTabSelector()

const route = useRoute()
const store = useStore()
const { t } = useI18n()
const jobId = computed(() => {
  return route.params.id
})
provide('jobId', jobId)

const job: any = computed(() => {
  return store.getters.jobsEdit
})

const showJobFinancials = computed(() => {
  return import.meta.env.VITE_APP_SHOW_JOB_FINANCIALS
})

const tab = ref(projectTabSelector.value)
const tabs = ref([
  'Overview',
  'Workorders',
  'Equipment',
  'Map',
  'Documents',
  'Notes',
])

if(showJobFinancials.value) {
  tabs.value.push('AdditionalCosts', 'CostOverview', 'Invoices')
}

onBeforeMount(async () => {
  await store.dispatch('jobs.fetchSingleForEdit', jobId.value)
  // UI
  store.commit('app.setPageTitle', t('job') + ' ' + job.value.job_name)
  store.commit('app.setBreadcrumbs', [
    { text: t('job', 2), routeName: 'JobsList' },
    { text: t('job') }
  ])
})

watch(tab, (val) => {
  if (val) {
    projectTabSelector.value = tab.value
    // store permanently
    if (tab.value) window.localStorage.setItem('jobs/View/projectTabSelector', tab.value)
  }
}, {immediate: true, deep: true})

</script>

<style lang="scss" scoped>

</style>
