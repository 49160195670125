<script setup lang="ts">
import {defineEmits} from "vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const emit = defineEmits(['trigger'])

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  },
  checkoutPeriod: {
    type: Object,
    required: false
  }
})

const emitTrigger = (action) => {
  emit('trigger', {
    invoice: props.invoice,
    action: action,
    checkoutPeriod: props.checkoutPeriod,
  })
}

</script>

<template>
  <template v-if="!invoice.status">
    <span @click="emitTrigger('openCreateDialog')" class="btn">{{ t('createProforma') }}</span>
  </template>
  <template v-else>
    <template v-if="invoice.status === 'PENDING'">
      <span @click="emitTrigger('openApproveDialog')" class="btn">{{ t('approve') }}</span>
      <span @click="emitTrigger('openRejectDialog')" class="btn btn-red">{{ t('reject') }}</span>
    </template>

    <template v-if="invoice.status === 'CONCEPT'">
      <span class="btn">{{ t('edit') }}</span>
    </template>
  </template>
</template>

<style scoped lang="scss">
.btn {
  height: auto;
  margin-right: 16px;
  cursor: pointer;
  color: rgb(245, 120, 40);
  font-weight: 500;
  text-decoration: underline;
  user-select: none;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.0892857143em;
}
.btn-red {
  color: rgb(255, 0, 0);
}
</style>
