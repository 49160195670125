import axios from 'axios'

export function fetchCheckoutPeriods (jobId: number|string) {
  return axios.get('/jobs/' + jobId + '/checkout-periods')
}

export function fetchCheckoutPeriodsCostOverview (jobId: number|string) {
  return axios.get('/jobs/' + jobId + '/checkout-periods/cost-overview')
}

export function fetchCheckoutPeriod (jobId: number|string, checkoutPeriodId: number|string, isFuture: boolean) {
  return axios.get('/jobs/' + jobId + '/checkout-periods/' + checkoutPeriodId, {
    params: {
      isFuture: isFuture
    }
  })
}

export function updateCheckoutPeriodStartDate (jobId: number|string, checkoutPeriodId: number|string, startDate: string) {
  return axios.post('/jobs/' + jobId + '/checkout-periods/' + checkoutPeriodId + '/start-date', {
    startDate: startDate
  })
}

export function updateCheckoutPeriodEndDate (jobId: number|string, checkoutPeriodId: number|string, endDate: string) {
  return axios.post('/jobs/' + jobId + '/checkout-periods/' + checkoutPeriodId + '/end-date', {
    endDate: endDate
  })
}

export function removeCheckoutPeriodEndDate (jobId: number|string, checkoutPeriodId: number|string,) {
  return axios.delete('/jobs/' + jobId + '/checkout-periods/' + checkoutPeriodId + '/end-date')
}
